import React, { useContext } from 'react'
import { UserContext } from "../providers/UserProvider";
import {
  Link
} from "react-router-dom";
import { MDBRow, MDBCol, MDBContainer, MDBIcon, MDBListGroup, MDBListGroupItem } from 'mdbreact'
import { signOut } from '../firebase';

const QuickMenu = ({ history }) => {
  const user = useContext(UserContext);

  return (
    <MDBListGroup >
      <Link to="/connect">
        <MDBListGroupItem color="indigo" text="white">
          <MDBIcon icon="user-friends" /> Connect
      </MDBListGroupItem>
      </Link>
      <Link to="/vscomputersetup">
      <MDBListGroupItem><MDBIcon icon="chess" /> Play</MDBListGroupItem>
      </Link>
      <MDBListGroupItem disabled><MDBIcon icon="history" /> Archive</MDBListGroupItem>
      <Link>
        <MDBListGroupItem onClick={() => { signOut() }}><MDBIcon icon="sign-out-alt" /> Sign Out</MDBListGroupItem>
      </Link>
    </MDBListGroup>
  )
}
export default QuickMenu