import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from "../providers/UserProvider";
import { firestore } from "../firebase";
import { MDBInput, MDBBtn, MDBIcon, MDBListGroup, MDBListGroupItem } from 'mdbreact'


const Connect = () => {
  const user = useContext(UserContext);
  const { photoURL, displayName, email, nickname } = user;
  const [errorMessage, setErrorMessage] = useState("");
  const [friendNickname, setFriendNickname] = useState("")
  const [friends, setFriends] = useState([])
  const [friendRequests, setFriendRequests] = useState([])
  const [friendUID, setFriendUID] = useState("")

  const inputRef = React.createRef();

  useEffect(() => {
    const fetchData = async () => {
      const friendSnaps = await Promise.all([
        firestore.collection(`users/${user.uid}/friends`).get(),
        firestore.collection(`users/${user.uid}/friendRequests`).get()])
      const [friends, requests] = friendSnaps
      setFriends(friends.docs.map(doc => doc.data()))
      setFriendRequests(requests.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }
    fetchData()
  }, [])

  async function handleChange(e) {
    e.persist()
    if (e.target.value.length < 8) {
      setErrorMessage("nicknames must be longer than 8 characters")
    } else if (e.target.value === nickname) {
      setErrorMessage("You cannot add yourself as a friend!")
    } else {
      const playerID = await checkIfPlayerExists(e.target.value)
      if (playerID === undefined) {
        setErrorMessage("There is no player with that nickname")
      } else {
        setFriendUID(playerID)
        setErrorMessage("")
      }
    }
    setFriendNickname(e.target.value)
    e.target.setCustomValidity(errorMessage)
  }

  async function checkIfPlayerExists(nickname) {
    const docRef = firestore.collection(`users`).where('nickname', '==', nickname);
    const querySnapshot = await docRef.get();
    return querySnapshot.docs.length > 0 ? querySnapshot.docs[0].id : undefined
  }

  async function addFriendRequest(friendNickname) {
    await firestore.collection(`users/${user.uid}/friends`).add({ nickname: friendNickname, uid: friendUID, pending: true });
    const query = firestore.collection(`users`).where('nickname', '==', friendNickname);
    const querySnapshot = await query.get();
    firestore.doc(`users/${querySnapshot.docs[0].id}`).collection('friendRequests').add({ nickname, uid: user.uid, type: "initial" })
  }

  async function allowFriend(friend) {
    await Promise.all([
      firestore.collection(`users/${user.uid}/friends`).add({ nickname: friend.nickname, uid: friend.uid, pending: false }),
      firestore.collection(`users/${friend.uid}/friendRequests`).add({ nickname, uid: user.uid, type: "allow" }),
      firestore.collection(`users/${user.uid}/friendRequests`).doc(friend.id).delete()
    ])
    const i = friendRequests.map(r => r.uid).indexOf(friend.uid)
    let newFriendRequests = [...friendRequests]
    newFriendRequests.splice(i, 1)
    setFriendRequests(newFriendRequests)
    setFriends([...friends, { nickname: friend.nickname, uid: friend.uid, pending: false }])
  }
  async function denyFriend(friend) {
    await Promise.all([
      firestore.doc(`users/${friend.uid}`).collection('friendRequests').add({ nickname, uid: user.uid, type: "deny" }),
      firestore.collection(`users/${user.uid}/friendRequests`).doc(friend.id).delete()
    ])
    const i = friendRequests.map(r => r.uid).indexOf(friend.uid)
    let newFriendRequests = [...friendRequests]
    newFriendRequests.splice(i, 1)
    setFriendRequests(newFriendRequests)
  }

  async function addFriend(event) {
    event.preventDefault();
    event.persist()
    event.target.className += " was-validated";
    await addFriendRequest(friendNickname)
  }
  return (
    <>
      <form
        className="needs-validation was-validated"
        onSubmit={addFriend}
        noValidate>
        <div style={{ display: 'flex' }}>
          <MDBInput style={{ minWidth: '200px' }} ref={inputRef} maxLength="30" label="Add Friend (Nickname)" placeholder="nickname or email" onChange={handleChange} required >
            <div className="invalid-feedback">
              {errorMessage}
            </div>
            <div className="valid-feedback"></div>
          </MDBInput>
          <MDBBtn style={{ margin: '30px' }} color="success" type="submit">
            <MDBIcon icon="plus" />
          </MDBBtn>
        </div>
      </form>
      Friends
      <MDBListGroup style={{ listStyleType: 'none' }}>
        {friends.map(friend => <MDBListGroupItem key={friend.uid}>{friend.nickname}{friend.pending ? " (pending)" : ""}</MDBListGroupItem>)}
      </MDBListGroup>
      <hr></hr>
      Requests
      <MDBListGroup style={{ listStyleType: 'none' }}>
        {friendRequests.filter((friend) => friend.type === "initial").map((friend) => (
          <MDBListGroupItem style={{padding:'.2rem', display: 'flex', alignItems:'center', justifyContent: "space-between" }} key={friend.uid}>{
            friend.nickname}
            <div><MDBBtn onClick={() => allowFriend(friend)} tag="a" size="sm" floating color="success">
              <MDBIcon icon="check" />
            </MDBBtn>
              <MDBBtn tag="a" onClick={() => denyFriend(friend)} size="sm" floating color="danger">
                <MDBIcon icon="times" />
              </MDBBtn>
            </div>
          </MDBListGroupItem>))}
      </MDBListGroup>
    </>
  )
}
export default Connect