import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCvMQl0hUhxuBmPpUteYqoGlRKg2CXLwmg",
    authDomain: "chess-286602.firebaseapp.com",
    databaseURL: "https://chess-286602.firebaseio.com",
    projectId: "chess-286602",
    storageBucket: "chess-286602.appspot.com",
    messagingSenderId: "935275182493",
    appId: "1:935275182493:web:1f9ba07dac4cdbbfc25774",
    measurementId: "G-5YRCDMLXYZ"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = async () => {
    return auth.signInWithPopup(provider);
  };

export const signOut =  () => {
  return auth.signOut()
}