import React from 'react'
import { Redirect } from 'react-router-dom'
import { MDBCol, MDBRow, MDBContainer } from 'mdbreact'


const LoginWall = ({ user, children, history }) => {
    if (user === undefined) {
        // history.location.pathname
        return <Redirect to={{ pathname: "/signin", state: { anything: "please" } }} />

    } else if (user === null) {
        return (
            
                <MDBRow style={{ display: 'flex', justifyContent: 'center' }}>
                    <MDBCol style={{ display: 'flex', justifyContent: 'center' }} size="8" lg="4">
                        <div className="spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </MDBCol>
                </MDBRow>
        )

    }
    else {
        return (
                    <MDBCol size="12" lg="4" style={{ display: 'flex', justifyContent: 'center' }}>
                        {children}
                    </MDBCol>
        )
    }
}


export default LoginWall
