import React, { Component, createContext } from "react";
import { auth, firestore } from "../firebase";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    user: null
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(async user => {
      if (user !== null) {
        const userRef = firestore.doc(`users/${user.uid}`);
        const userSnapshot = await userRef.get();
        const existingData = userSnapshot.data()
        const { displayName, uid, email } = user
        const userData = { displayName, uid, email }
        this.setState({ user: Object.assign({}, userData, existingData) });
      } else {
        this.setState({user: undefined})
      }
    });
  };
  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;