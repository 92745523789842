import React, { useContext } from 'react'
import { UserContext } from "../providers/UserProvider";
import { MDBListGroupItem, MDBIcon } from 'mdbreact';
import {Link} from 'react-router-dom'

const VSComputerSetup = () => {
  const user = useContext(UserContext);
  const { photoURL, displayName, email } = user;

  return (
    <>
    {/* <div>Select Mode</div> */}
    <Link to="/vscomputer/1000">
      <MDBListGroupItem><MDBIcon icon="chess" />Play</MDBListGroupItem>
      </Link>
    </>
  )
}
export default VSComputerSetup