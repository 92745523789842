import React, { useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import { useParams } from "react-router-dom";
import {MDBBtn} from 'mdbreact'
import { UserContext } from "../providers/UserProvider";
import HumanGame from './HumanGame'


const VSHuman = () => {
  const user = useContext(UserContext);
  const { photoURL, displayName, email } = user;
  const { uid } = useParams();
  const [socket, setSocket] = useState()
  const [otherPlayerID, setOtherPlayerID] = useState()
  const [roomID, setRoomID] = useState()
  useEffect(() => {
    const theSocket = io('ws://192.168.86.48:5000')
    setSocket(theSocket)
    theSocket.emit('challenge', {hostUID: user.uid, otherUID: uid });
    // handle the event sent with socket.send()
    theSocket.on('message', data => {
      console.log(data);
    });
    theSocket.on('play', data => {
      console.log(data)
      setOtherPlayerID(data.p2SocketID)
      setRoomID(data.room)
    });
  }, [])

  const acceptChallenge = () => {
    socket.emit('accept-challenge', {hostUID:'6S1tFDX1TcPhENcCvrskargiSgJ3', myUID: '4vCssGeSU5QCTQp3cU7WszhMDKW2'})
  }

  console.log(roomID)
  return (
    <>
    <MDBBtn onClick={acceptChallenge}>Play</MDBBtn>
      <HumanGame roomID={roomID} socket={socket}/>
    </>
  )
}
export default VSHuman