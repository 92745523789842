import React from 'react'
import { signInWithGoogle } from '../firebase'
import { Redirect } from 'react-router-dom'
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle } from 'mdbreact'

const SignIn = (props) => {
  const { history, user, location } = props
  async function signIn() {
    await signInWithGoogle()
    return <Redirect to='/' />
  }
  if (!user) {
    return (
      <MDBContainer fluid>
        <MDBRow style={{ display: 'flex', justifyContent: 'center' }}>
          <MDBCol style={{ display: 'flex', justifyContent: 'center' }}>
            <MDBCard style={{ width: "22rem" }}>
              <MDBCardImage style={{ marginTop:'10px'}} top className="img-fluid" src="./logo512.png" waves />
              <MDBCardBody style={{ display: 'flex', alignItems:'center', flexDirection:'column', justifyContent: 'center' }}>
                <MDBCardTitle>blunder.land</MDBCardTitle>
                <MDBCardText>
                  play chess
          </MDBCardText>
                <MDBBtn
                  onClick={signIn}
                  color="danger">
                  Sign In with Google
              </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer >
    )
  }
  else if (user.nickname === undefined) {
    return <Redirect to="/setup" />

  } else {
    return <Redirect to={'/'} />

  }
}
export default SignIn