import React, { useContext } from 'react'
import { UserContext } from "../providers/UserProvider";
import Game from './Game'

const VSComputer = () => {
  const user = useContext(UserContext);
  const { photoURL, displayName, email } = user;

  return (
    <>
      <Game/>
    </>
  )
}
export default VSComputer