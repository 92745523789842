import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { createBrowserHistory } from 'history'
import { firestore } from './firebase'
import SignIn from './components/SignIn.js'
import QuickMenu from './components/QuickMenu'
import UserProvider, { UserContext } from "./providers/UserProvider";
import Setup from './components/Setup'
import Connect from './components/Connect'
import LoginWall from './components/LoginWall'
import VSComputerSetup from './components/VSComputerSetup'
import VSComputer from './components/VSComputer'
import VSHuman from './components/VSHuman'


async function removePendingFriendStatus(user, request){
  const [friends] = await Promise.all([
  firestore.collection(`users/${user.uid}/friends`).where("uid", '==',request.uid).get(),
  firestore.collection(`users/${user.uid}/friendRequests`).doc(request.friendRequestId).delete()])
  firestore.collection(`users/${user.uid}/friends`).doc(friends.docs[0].id).set({pending:false  }, { merge: true })
}

async function deleteFriend(user, request){
  const [friends] = await Promise.all([
  firestore.collection(`users/${user.uid}/friends`).where("uid", '==',request.uid).get(),
  firestore.collection(`users/${user.uid}/friendRequests`).doc(request.friendRequestId).delete()])
  firestore.collection(`users/${user.uid}/friends`).doc(friends.docs[0].id).delete()
}


function reconcileFriendRequests(user) {
  const fetchData = async () => {
    const queries = await Promise.all([
      firestore.collection(`users/${user.uid}/friends`).get(),
      firestore.collection(`users/${user.uid}/friendRequests`).get()])
    const [friendSnaps, requestSnaps] = queries
    const requests = requestSnaps.docs.map(doc => ({ ...doc.data(), friendRequestId: doc.id }))
    for (let i = 0; i < requests.length; i++) {
      if (requests[i].type === "allow") {
        await removePendingFriendStatus(user, requests[i])
      } else if (requests[i].type === "deny") {
        await deleteFriend(user, requests[i])
      }
    }

  }
  if (user) {
    fetchData()
  }
}

export default function App() {
  const history = createBrowserHistory()
  const user = useContext(UserContext)
  useEffect(() => reconcileFriendRequests(user), [user])
  return (
    <UserProvider>
      <Router history={history}>
        <Switch>
          <Route exact path='/'>
            <LoginWall history={history} user={user}>
              <QuickMenu />
            </LoginWall>
          </Route>
          <Route path='/signin'>
            <SignIn user={user} history={history} />
          </Route>
          <Route path='/setup'>
            <LoginWall user={user}>
              <Setup user={user} history={history} />
            </LoginWall >
          </Route>
          <Route path='/vscomputersetup'>
            <LoginWall user={user}>
              <VSComputerSetup user={user} history={history} />
            </LoginWall >
          </Route>
          <Route path='/vscomputer/:elo'>
            <LoginWall user={user}>
              <VSComputer user={user} history={history} />
            </LoginWall >
          </Route>
          <Route path='/vshuman/:uid'>
            <LoginWall user={user}>
              <VSHuman user={user} history={history} />
            </LoginWall >
          </Route>
          <Route path='/connect'>
            <LoginWall history={history} user={user}>
              <Connect user={user} />
            </LoginWall>
          </Route>
        </Switch>
      </Router>
    </UserProvider>
  )
}