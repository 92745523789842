import React, { Component } from "react";
import Chess from "chess.js";

import Chessboard from "chessboardjsx";

export default class HumanGame extends Component {
  constructor(props) {
    super(props)
    this.state = { fen: "start" };
    this.stockfish = new Worker("/stockfish/stockfish.js");
  }

  allowDrag({ sourceSquare, piece }) {
    // do not pick up pieces if the this.game is over
    if (this.game.game_over()) return false
    // if (this.game.turn() !== 'w') {
    //   return false
    // }
    // only pick up pieces for the side to move
    if ((this.game.turn() === 'w' && piece.search(/^b/) !== -1) ||
      (this.game.turn() === 'b' && piece.search(/^w/) !== -1)) {
      return false
    }
    return true
  }

  // update the board position after the piece snap
  // for castling, en passant, pawn promotion
  onSnapEnd(board) {
    console.log(board)
    board.position(this.game.fen())
  }

  onDrop({ sourceSquare, targetSquare, piece }) {
    var move = this.game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q' // NOTE: always promote to a queen for example simplicity
    })

    // illegal move
    if (move === null) { console.log('illegal move'); return 'snapback' }

    var status = ''

    var moveColor = 'White'
    if (this.game.turn() === 'b') {
      moveColor = 'Black'
    }

    // checkmate?
    if (this.game.in_checkmate()) {
      status = 'this.game over, ' + moveColor + ' is in checkmate.'
    }

    // draw?
    else if (this.game.in_draw()) {
      status = 'Game over, drawn position'
    }

    // game still on
    else {
      status = moveColor + ' to move'

      // check?
      if (this.game.in_check()) {
        status += ', ' + moveColor + ' is in check'
      }
    }
    console.log(this.game.fen())
    this.setState({ status, fen: this.game.fen() })
    const fen = this.game.fen()
    const pgn = this.game.pgn()

    //take turn for black

    // this.stockfish.postMessage(`position fen ${this.game.fen()}`);
    // this.stockfish.postMessage("go depth 15");
    console.log(move)
    this.props.socket.to(this.props.roomID).emit(move)
  }

  componentDidMount() {
    this.game = new Chess();
    this.stockfish.onmessage = event => {
      const [eventType, move] = event.data.split(' ')
      if (eventType === 'bestmove') {
        var sourceSquare = move.substr(0, 2);
        var targetSquare = move.substr(2, 2);
        var legalMove = this.game.move({
          from: sourceSquare,
          to: targetSquare,
          promotion: 'q' // NOTE: always promote to a queen for example simplicity
        })
        this.setState({ cpuMove: move, fen: this.game.fen() })
      }
    };
  }

  render() {
    console.log(this.props)
    if (this.props.roomID) {

      return (
        <div>
          <Chessboard
            width={320}
            id="random"
            position={this.state.fen}
            transitionDuration={300}
            allowDrag={this.allowDrag.bind(this)}
            onDrop={this.onDrop.bind(this)}
            onSnapEnd={this.onSnapEnd.bind(this)}
            boardStyle={{
              borderRadius: "5px",
              boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`
            }}
          />
        </div >
      )
    } else {
      return (<div>waiting for player</div>)
    }
  }
}
