import React, { useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { MDBBtn, MDBInput, MDBIcon, MDBCardBody, MDBCard, MDBCardTitle, MDBCardImage, MDBCardText } from 'mdbreact'
import Filter from 'bad-words'
import nGram from 'n-gram'
import { auth, firestore } from "../firebase";


const filter = new Filter();


const Setup = ({ user, history }) => {
  const [nickname, setNickname] = useState("");
  const [isProfane, setProfane] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const inputRef = React.createRef();


  async function nicknameIsTaken(nickname) {
    const docRef = firestore.collection(`users`).where('nickname', '==', nickname);
    const querySnapshot = await docRef.get();
    return querySnapshot.docs.length > 0 ? true : false
  }

  async function handleChange(e) {
    e.persist()
    const nickname = e.target.value
    const gramSizes = [3, 4, 5, 7]
    let words = []
    gramSizes.forEach(size => {
      words = words.concat(nGram(size)(e.target.value))
    })
    const isProfane = words.reduce((acc, curr) => filter.isProfane(curr) || acc, false)
    setNickname(e.target.value)
    setProfane(isProfane)
    if (isProfane) {
      const message = "You cannot use profanity in your nickname"
      e.target.setCustomValidity(message)
      setErrorMessage(message)
    }
    else if (nickname.length < 8) {
      const message = "Your nickname must be longer than 8 characters"
      e.target.setCustomValidity(message)
      setErrorMessage(message)
    } else {
      e.target.setCustomValidity("")
      setErrorMessage("")
    }

    if (nickname.length >= 8) {
      const taken = await nicknameIsTaken(nickname)
      if (taken) {
        const message = "This nickname is already taken"
        e.target.setCustomValidity(message)
        setErrorMessage(message)
      }
    }
  }

  async function saveNickname(event) {

    event.preventDefault();
    event.target.className += " was-validated";
    await firestore.doc(`users/${user.uid}`).set({ nickname });
    window.location.reload()
  }
  if (user.nickname === undefined) {
    return (
      <MDBCard style={{ width: "22rem" }}>
      <MDBCardImage style={{ marginTop:'10px'}} top className="img-fluid" src="./logo512.png" waves />
      <MDBCardBody style={{ display: 'flex', alignItems:'center', flexDirection:'column', justifyContent: 'center' }}>
        <MDBCardTitle>Create Your Profile</MDBCardTitle>
        <MDBCardText>
          you need a nickname before you can play chess!
  </MDBCardText>
  <form
      className="needs-validation was-validated"
      onSubmit={saveNickname}
      noValidate>
      <MDBInput ref={inputRef} maxLength="30" label="Nickname" onChange={handleChange} required >
        <div className="invalid-feedback">
          {errorMessage}
        </div>
        <div className="valid-feedback">looks good!</div>
      </MDBInput>
      <MDBBtn color="success" type="submit">
        Submit
          </MDBBtn>
    </form>
      </MDBCardBody>
    </MDBCard>
    
    
    
    )
  } else {
    return <Redirect to="/" />
  }
}
export default Setup